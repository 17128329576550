import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setLandingPageData(JsonData);

    // Initialize SmoothScroll after data is set
    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 1000,
      speedAsDuration: true,
    });

    // Check if it's the first time user visits the page
    const isFirstVisit = localStorage.getItem("firstVisit");
    if (!isFirstVisit) {
      setShowPopup(true);
      localStorage.setItem("firstVisit", "true");
    }

    // Cleanup function to remove SmoothScroll instance if needed
    return () => {
      scroll.destroy();
    };
  }, []);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Welcome!</h2>
            <p>We acknowledge the Traditional Owners and Custodians of the lands on which we work and pay our respects to Elders past, present and emerging. Sovereignty has never been ceded. The land we live, work and learn on always was, and always will be, Aboriginal land.</p>
            <button onClick={handlePopupClose}>Close</button>
          </div>
        </div>
      )}
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;